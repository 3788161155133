"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HTML_BLOCK_CONFIG = void 0;
// Config from https://github.com/intercom/intercom/blob/master/gems/intercom-message-content-sanitization/lib/message_content_sanitization/sanitizers/config/custom_template.rb
var HTML_BLOCK_CONFIG = {
  ADD_TAGS: ["a", "abbr", "acronym", "address", "area", "article", "aside", "audio", "b", "bdi", "bdo", "big", "blink", "blockquote", "body", "br", "button", "canvas", "caption", "center", "cite", "code", "col", "colgroup", "content", "data", "datalist", "dd", "decorator", "del", "details", "dfn", "dialog", "dir", "div", "dl", "dt", "element", "em", "fieldset", "figcaption", "figure", "font", "footer", "form", "h1", "h2", "h3", "h4", "h5", "h6", "head", "header", "hgroup", "hr", "html", "i", "img", "input", "ins", "kbd", "label", "legend", "li", "main", "map", "mark", "marquee", "menu", "menuitem", "meta", "meter", "nav", "nobr", "ol", "optgroup", "option", "output", "p", "picture", "pre", "progress", "q", "rp", "rt", "ruby", "s", "samp", "section", "select", "shadow", "small", "source", "spacer", "span", "strike", "strong", "style", "sub", "summary", "sup", "table", "tbody", "td", "template", "textarea", "tfoot", "th", "thead", "time", "title", "tr", "track", "tt", "u", "ul", "var", "video", "wbr"],
  ADD_ATTR: ["abbr", "align", "allowfullscreen", "alt", "axis", "background", "bgcolor", "border", "cellpadding", "cellspacing", "charset", "cite", "class", "colspan", "content", "datetime", "dir", "frameborder", "height", "href", "http-equiv", "id", "ite", "lang", "name", "pubdate", "reversed", "rowspan", "scope", "span", "src", "start", "style", "summary", "target", "target", "title", "type", "valign", "width", "xmlns"]
};
exports.HTML_BLOCK_CONFIG = HTML_BLOCK_CONFIG;