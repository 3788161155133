var lodashToArray = require('lodash.toarray');
var flatten = require('./flatten');
var forEachObject = require('./for_each_object');
var includes = require('./includes');
var mapObject = require('./map_object');
var filterObject = require('./filter_object');
var values = require('./values');
var unique = require('./unique');
var isUndefined = require('./is_undefined');

var ucs2decode = function(string) {
  // Swiped from https://github.com/bestiejs/punycode.js
  // and modified to return list of string-index-pairs instead of just indexes.
  var output = [],
      counter = 0,
      length = string.length,
      value,
      extra,
      valueChar,
      extraChar;
  while (counter < length) {
    valueChar = string.charAt(counter);
    value = string.charCodeAt(counter++);
    if (value >= 0xD800 && value <= 0xDBFF && counter < length) {
      // high surrogate, and there is a next character
      extraChar = string.charAt(counter);
      extra = string.charCodeAt(counter++);
      if ((extra & 0xFC00) == 0xDC00) { // low surrogate
        output.push([valueChar + extraChar, ((value & 0x3FF) << 10) + (extra & 0x3FF) + 0x10000]);
      } else {
        // unmatched surrogate; only append this code unit, in case the next
        // code unit is the high surrogate of a surrogate pair
        output.push([valueChar, value]);
        counter--;
      }
    } else {
      output.push([valueChar, value]);
    }
  }
  return output;
};

var ESCAPE_PREFIX = '__ic_';

var SUPPORTED_EMOJI = require('./emoji.json');

var DATA_FROM_UNICODE = {};
SUPPORTED_EMOJI.forEach(function(emojis, categoryIndex) {
  emojis.forEach(function(emoji) {
    DATA_FROM_UNICODE[emoji.unicode] = {
      identifier: emoji.identifier,
      isEmojiBase: emoji.isEmojiBase,
      supportedTwemoji: emoji.supportedTwemoji,
      categoryIndex: categoryIndex,
    };
  });
});

var UNICODE_FROM_ASCII = {
  ':-)'  : '\ud83d\ude00',
  ':-D'  : '\ud83d\ude03',
  ';-)'  : '\ud83d\ude09',
  '}-)'  : '\ud83d\udc7f',
  ':-o'  : '\ud83d\ude2e',
  ':-O'  : '\ud83d\ude2e',
  ':-/'  : '\ud83d\ude15',
  ':-\\' : '\ud83d\ude15',
  'x-('  : '\ud83d\ude29',
  'X-('  : '\ud83d\ude29',
  ':-('  : '\ud83d\ude1e',
  'B-)'  : '\ud83d\ude0e',
  ':-p'  : '\ud83d\ude1b',
  ':-P'  : '\ud83d\ude1b',
  ':-@'   : '\ud83d\ude20',
  ':-|'  : '\ud83d\ude10',
  ':-$'   : '\ud83d\ude33'
};
forEachObject(UNICODE_FROM_ASCII, function(v, k) {
  DATA_FROM_UNICODE[v][ESCAPE_PREFIX + 'ascii'] = k; // NB: Collisions OK.
});

var uglyNativeEmoji = [
  /* Following rendered poorly by Chrome 41.0.2272.118 */
  '⁉', '⁉️', '‼️', '✂', '✈', '✉', '❤', '✌', '✏', '⚠', '🈷', '🈂', 'Ⓜ', 'Ⓜ️', '✒', '✔', '✖', '◼',
  '◻', '™', '☀', '☁', '♠', '♠️', '♣', '♣️', '♥', '♥️', '♦', '♦️', '♨', '▪', '▫', 'ℹ', '↔', '↔️', '↕', '↖', '↖️', '↗', '↗️',
  '↘', '↘️', '↙', '↙️', '☑', '〰', '♻', '〽', '▶', '▶️', '☝', '✳', '✴', '❄', '❇', '⬆', '⬇', '⬅',
  '➡', '◀', '◀️', '↩', '↪', '⤴', '⤴️', '⤵', '⤵️', '㊗', '㊙', '☺', '☺️', '☹️', '☹', '‼', '🅰', '🅰️', '🅱', '🅱️', '🅿', '🅿️', '🅾', '🅾️',
  '\ud83c\uddef', '\ud83c\uddf0', '\ud83c\udde9', '\ud83c\udde8', '\ud83c\uddfa',
  '\ud83c\uddeb', '\ud83c\uddea', '\ud83c\uddee', '\ud83c\uddf7', '\ud83c\uddec',
  /* Following rendered poorly by Firefox 36.0.4 */
  '☔', '⭐', '⚡', '☕', '☎', '⚓', '♈', '♉', '♐', '♑', '♒', '♓', '♿', '♊', '♋', '♌',
  '♍', '♎', '♏', '◾', '◽', '⚫', '⚪'
]; // ' Atom bug: thinks we need closing quote.

var UNICODE_FROM_IDENTIFIER = {};
forEachObject(DATA_FROM_UNICODE, function(v, k) {
  UNICODE_FROM_IDENTIFIER[ESCAPE_PREFIX + v.identifier] = k;
});

var N_GROUPS = 8;
var GROUPED_DATA_UNICODE = SUPPORTED_EMOJI.map(function(emojis) {
  return emojis.map(function(l) {
    return l.unicode;
  });
});

var splitOnAsciiEmojisFixedLength = function(intermoji, s, len) {
  var res = [],
      i = 0,
      j = 0;
  while(i+len <= s.length) {
    var t = s.slice(i, i+len);
    if(intermoji.isSupportedAscii(t) && asciiEmojiSurroundedByBlank(s, i-1, i+len)) {
      if(j < i) {
        res.push(s.slice(j, i));
      }
      res.push(t);
      i += len;
      j = i;
    }
    else {
      ++i;
    }
  }
  if(j < s.length) {
    res.push(s.slice(j, s.length));
  }
  return res;
};

var asciiEmojiSurroundedByBlank = function(str, precedingIndex, followingIndex) {
  var precedingCharIsBlank = false;
  var followingCharIsBlank = false;

  if (precedingIndex < 0 || /\s/.test(str.charAt(precedingIndex))) {
    precedingCharIsBlank = true;
  }

  if (followingIndex >= str.length || /\s/.test(str.charAt(followingIndex))) {
    followingCharIsBlank = true;
  }

  return precedingCharIsBlank && followingCharIsBlank;
};

module.exports = {
  getGroupRepresentatives: function() {
    return [
      ['😄', 'Smileys & People'],
      ['🌸', 'Animals & Nature'],
      ['🍇', 'Food & Drink'],
      ['🌍', 'Travel & Places'],
      ['🎈', 'Activities'],
      ['🔔', 'Objects'],
      ['🔠', 'Symbols'],
      ['🇮🇪', 'Flags'],
    ];
  },

  getUglyNativeEmoji: function() {
    return uglyNativeEmoji.slice(); // .slice() to return by value.
  },

  isUglyNativeEmoji: function(s) {
    return includes(uglyNativeEmoji, s);
  },

  hasNativeSupport: function(document) {
    // Based on: https://gist.github.com/mwunsch/4710561
    var context, emoji = '\uD83D\uDE00';
    if(!document.createElement('canvas').getContext) {
      return false;
    }
    context = document.createElement('canvas').getContext('2d');
    if(typeof context.fillText !== 'function') {
      return false;
    }
    context.textBaseline = 'top';
    context.font = '32px Arial';
    context.fillText(emoji, 0, 0);
    var imageData = context.getImageData(16, 16, 1, 1);
    if (!imageData) return false;
    return (imageData.data[0] !== 0);
  },

  isSupportedUnicode: function(unicode_str) {
    return DATA_FROM_UNICODE.hasOwnProperty(unicode_str);
  },

  isEmojiBaseUnicode: function(unicode_str) {
    return DATA_FROM_UNICODE[unicode_str].isEmojiBase;
  },

  identifierFromUnicode: function(unicode_str) {
    return DATA_FROM_UNICODE[unicode_str].identifier;
  },

  asciiFromUnicode: function(unicode_str) {
    return DATA_FROM_UNICODE[unicode_str][ESCAPE_PREFIX + 'ascii'];
  },

  groupFromUnicode: function(unicode_str) {
    return DATA_FROM_UNICODE[unicode_str].categoryIndex;
  },

  isSupportedAscii: function(ascii) {
    return UNICODE_FROM_ASCII.hasOwnProperty(ascii);
  },

  unicodeFromAscii: function(ascii) {
    return UNICODE_FROM_ASCII[ascii];
  },

  isSupportedIdentifier: function(identifier) {
    return UNICODE_FROM_IDENTIFIER.hasOwnProperty(ESCAPE_PREFIX + identifier);
  },

  unicodeFromIdentifier: function(identifier) {
    return UNICODE_FROM_IDENTIFIER[ESCAPE_PREFIX + identifier];
  },

  N_GROUPS: N_GROUPS,

  prettyEmoticonsUnicodeGroups: function() {
    return GROUPED_DATA_UNICODE.map(function(emojis) {
      return emojis.filter(function(emoji) {
        if (!includes(uglyNativeEmoji, emoji)) {
          return emoji;
        }
      });
    });
  },

  allEmoticonsUnicodeGroups: GROUPED_DATA_UNICODE,

  allEmoticonsUnicodeList: Object.keys(DATA_FROM_UNICODE),

  allEmoticonsIdentifierList: mapObject(DATA_FROM_UNICODE, function(v, _) {
    return v.identifier;
  }),

  prettyEmoticonsIdentifierList: function() {
    return mapObject(
      filterObject(
        DATA_FROM_UNICODE,
        function(v, k) {
          return !includes(uglyNativeEmoji, k) && v.isEmojiBase;
        }
      ),
      function(v, _) {
        return v.identifier;
      }
    );
  },

  asciiEmoticonsUnicodeList: unique(values(UNICODE_FROM_ASCII)),

  asciiEmoticonsIdentifierList: unique(values(UNICODE_FROM_ASCII)).map(function(v) {
    return DATA_FROM_UNICODE[v].identifier;
  }),

  MIN_ASCII_LENGTH: 3,
  MAX_ASCII_LENGTH: 3, // To allow for speed up splitOnAsciiEmojis

  asciiEmoticonsAsciiList: Object.keys(UNICODE_FROM_ASCII),

  splitOnUnicodeEmojis: function(s) {
    if(isUndefined(s)) { return []; }
    var charArr = lodashToArray(s);
    var strEmojiArr = [];
    var shouldNotAppend = true;
    charArr.forEach(function(char) {
      if (this.isSupportedUnicode(char)) {
        strEmojiArr.push(char);
        shouldNotAppend = true;
      } else {
        if (shouldNotAppend) {
          strEmojiArr.push(char);
        } else {
          var lastItem = strEmojiArr.pop();
          strEmojiArr.push(lastItem + char);
        }
        shouldNotAppend = false;
      }
    }.bind(this));
    return strEmojiArr;
  },

  splitOnAsciiEmojis: function(s) {
    if(isUndefined(s)) { return []; }
    var res = [s];
    for(var len = this.MAX_ASCII_LENGTH; len >= this.MIN_ASCII_LENGTH; --len) {
      var l = [];
      for(var i = 0; i < res.length; ++i) {
        l = l.concat(splitOnAsciiEmojisFixedLength(this, res[i], len));
      }
      res = l;
    }
    return res;
  },

  getAllModifiedForBaseEmoji: function(identifier) {
    var res = [];
    if(identifier === '') return res;
    for(var prop in DATA_FROM_UNICODE) {
        if(prop.indexOf(identifier)>-1) {
          res.push(prop);
        }
    }
    return res;
  },

  splitOnUnicodeAndAsciiEmojis: function(s) {
    if(isUndefined(s)) { return []; }
    return flatten(
      this.splitOnUnicodeEmojis(s).map(function(w) {
        return this.splitOnAsciiEmojis(w);
      }.bind(this))
    );
  },

  substituteUnicodeForAsciiEmojis: function(s) {
    if(isUndefined(s) || s === null) { return ''; }
    return this.splitOnAsciiEmojis(s).map(function(w) {
      return this.isSupportedAscii(w) ? this.unicodeFromAscii(w) : w;
    }.bind(this)).reduce(function(memo, c) {
      return memo + c;
    }, '');
  },

  wrapUnicodeEmojiInTitledSpans: function(size, s, className, roleImage) {
    if(isUndefined(s)) { return ''; }
    return this.splitOnUnicodeEmojis(s).map(function(w) {
      if(this.isSupportedUnicode(w)) {
        if(this.isUglyNativeEmoji(w)) {
          return this.fallbackImage(size, w, className);
        }
        else {
          var accessibilityAttributes = '';
          if (roleImage) {
            accessibilityAttributes = ' role="img" aria-label="' + this.identifierFromUnicode(w) + '"';
          }
          return '<span title="' + this.identifierFromUnicode(w) + '"' + accessibilityAttributes + '>' + w + '</span>';
        }
      }
      else {
        return w;
      }
    }.bind(this)).reduce(function(memo, c) {
      return memo + c;
    }, '');
  },

  substituteUnicodeForColonified: function(string) {
    // Swiped from: https://github.com/intercom/embercom-composer/blob/a2bed7532ab17a3d782db6a8c146ccd9ffa40311/addon/lib/intermoji.js
    var wrapInColons = function(string) { return ":" + string + ":"; };
    var outsideMoji = true;
    var token = '';
    var result = '';
    for (var i = 0; i < string.length; i ++) {
      if (string[i] === ':') {
        if (outsideMoji) {
          outsideMoji = false;
          result += token;
        } else {
          outsideMoji = true;
          result += this.isSupportedIdentifier(token) ? this.unicodeFromIdentifier(token) : wrapInColons(token);
        }
        token = '';
      } else {
        token += string[i];
      }
    }
    if (!outsideMoji) {
      result += ':';
    }
    return result += token;
  },

  codepointIndexFromUnicode: function(s) {
    return ucs2decode(s).map(function(char) {
      return char[1];
    });
  },

  fallbackImage: function(size, s, className) {
    if(isUndefined(s)) { return ''; }
    return this.splitOnUnicodeAndAsciiEmojis(s).map(function(w) {
      if(this.isSupportedAscii(w)) {
        return this.twemojiSpanTag(size, this.unicodeFromAscii(w), className);
      }
      else if(this.isSupportedUnicode(w)) {
        return this.twemojiSpanTag(size, w, className);
      }
      else {
        return w;
      }
    }.bind(this)).join('');
  },

  twemojiStyleString: function(size, unicode_str) {
    return 'display:inline-block;' +
           'height:' + size + 'px;' +
           'width:' + size + 'px;' +
           'background-image:url(' + this.twemojiSVGUri(unicode_str) + ');' +
           'background-size:contain;';
  },

  twemojiSpanTag: function(size, unicode_str, className) {
    if(isUndefined(className)) { className = "intermoji-default-class"; }
    return '<span ' +
           'style="' + this.twemojiStyleString(size, unicode_str) + '" ' +
           'title="' + this.identifierFromUnicode(unicode_str) + '" ' +
           'class="' + className + '" ' +
           'role="img" ' +
           'aria-label="' + this.identifierFromUnicode(unicode_str) + '"' +
           '></span>';
  },

  twemojiSVGUri: function(s) {
    var supportedTwemoji = this.getSupportedTwemoji(s);
    var codepointStrings = this.codepointIndexFromUnicode(supportedTwemoji).map(function(codepoint) {
      return codepoint.toString(16);
    });
    var filename = codepointStrings.join('-').toLowerCase() + '.svg';
    return 'https://js.intercomcdn.com/images/stickers/v2/svg/' + filename;
  },

  getSupportedTwemoji: function (s) {
    if (s in DATA_FROM_UNICODE) {
      return DATA_FROM_UNICODE[s].supportedTwemoji || s;
    } else {
      return s;
    }
  },

  getEmojiFromSupportedTwemoji: function(s) {
    return Object.keys(DATA_FROM_UNICODE).find(function(emoji) {
      return DATA_FROM_UNICODE[emoji].supportedTwemoji === s;
    }) || s;
  },

};
