var mapObject = require('./map_object');

module.exports = function(object, callback) {
  var newObject = {};

  mapObject(object, function(value, key) {
    if (callback(value, key)) {
      newObject[key] = value;
    }
  });

  return newObject;
};
