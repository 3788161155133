"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var ELEMENT_ATTRIBUTE_VALIDATIONS = {
  iframe: {
    // This list should match the one in interblocks.ts https://github.com/intercom/interblocks.ts/blob/2ce1fc14f7b05b15f44bc763c675953f1c90373a/src/compile/validations.ts#L76
    src: [/^(https:)?\/\/www\.youtube(-nocookie)?\.com\/embed\/.+/, /^(https:)?\/\/www\.loom\.com\/embed\/.+/, /^(https:)?\/\/play\.vidyard\.com\//, /^(https:)?\/\/player\.vimeo\.com\/video\/.+/, /^(https:)?\/\/fast\.wistia\.(net|com)\/embed\/iframe\/.+/, /^(https:)?\/\/players\.brightcove\.net\/.+/, /^(https:)?\/\/content\.jwplatform\.com\/players\/.+/, /^(https:)?\/\/web\.microsoftstream\.com\/embed\/video\/.+/, /^(https:)?\/\/([\w-]+)\.sharepoint\.com\/:v:\/.+/, /^(https:)?\/\/share\.synthesia\.io\/embeds\/videos\/.+/, /^(https:)?\/\/embed\.app\.guidde\.com\/playbooks\/.+/, /^(https:)?\/\/share\.descript\.com\/embed\/.+/]
  }
};
var _default = {
  IFRAME: function IFRAME(node, _data) {
    var src = node.attributes["src"];
    if (!src || !ELEMENT_ATTRIBUTE_VALIDATIONS.iframe.src.some(function (regex) {
      return regex.test(src.value);
    })) {
      node.remove();
    }
  }
};
exports["default"] = _default;