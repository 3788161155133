"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EMAIL_TEMPLATE_CONFIG = void 0;
var _htmlBlock = require("./html-block.js");
// Config from https://github.com/intercom/intercom/blob/master/gems/intercom-message-content-sanitization/lib/message_content_sanitization/sanitizers/config/custom_template.rb
var EMAIL_TEMPLATE_CONFIG = Object.assign({}, _htmlBlock.HTML_BLOCK_CONFIG, {
  WHOLE_DOCUMENT: true
});
exports.EMAIL_TEMPLATE_CONFIG = EMAIL_TEMPLATE_CONFIG;