var forEachObject = require('./for_each_object');

module.exports = function(object, callback) {
  var array = [];

  forEachObject(object, function(value, key) {
    array.push(callback(value, key));
  });

  return array;
};
