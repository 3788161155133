/* RESPONSIBLE TEAM: team-reporting */
import ApplicationAdapter from 'embercom/adapters/application';

export default class ReportAccessControlListAdapter extends ApplicationAdapter {
  pathForType() {
    return 'reporting/report_access_control_list';
  }

  urlForQuery(query: $TSFixMe, modelName: $TSFixMe) {
    if (query['report_id']) {
      return `${super.urlForQuery(query, modelName)}/list_by_report_id`;
    }
    return `${super.urlForQuery(query, modelName)}/list_by_admin_id`;
  }

  urlForFindAll(modelName: $TSFixMe, snapshot: $TSFixMe) {
    return `${super.urlForFindAll(modelName, snapshot)}/list_by_admin_id`;
  }
}
