/*
  modified version of
  https://github.com/catdad/canvas-confetti/blob/941551e799a1f3f33affb8a2c837c2be6ed1f2ba/src/confetti.js
*/

let frame = (function() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function(cb) {
      window.setTimeout(cb, 1000 / 60);
    }
  );
})();

let defaults = {
  particleCount: 50,
  angle: 90,
  spread: 45,
  startVelocity: 45,
  decay: 0.9,
  ticks: 200,
  x: 0.5,
  y: 0.5,
  zIndex: 100,
  // https://pulse.internal.intercom.io/foundations/colors#avatars
  colors: [
    '#fd3a57',
    '#fa7080',
    '#ffb3c6',
    '#f0b3fc',
    '#c701f1',
    '#9401b2',
    '#261860',
    '#286efa',
    '#6b9cff',
    '#d3e2fd',
    '#286efa',
    '#6b9cff',
    '#d3e2fd',
    '#8fe3eb',
    '#0accac',
    '#17c65d',
    '#8ae2ad',
    '#b8e65c',
    '#f7d50b',
    '#ffb848',
    '#ff8b47',
  ],
};

let animationObj;

function convert(val, transform) {
  return transform ? transform(val) : val;
}

function isOk(val) {
  return !(val === null || val === undefined);
}

function prop(options, name, transform) {
  return convert(options && isOk(options[name]) ? options[name] : defaults[name], transform);
}

function getOrigin(options) {
  let origin = prop(options, 'origin', Object);
  origin.x = prop(origin, 'x', Number);
  origin.y = prop(origin, 'y', Number);

  return origin;
}

function randomPhysics(opts) {
  let radAngle = opts.angle * (Math.PI / 180);
  let radSpread = opts.spread * (Math.PI / 180);

  return {
    x: opts.x,
    y: opts.y,
    wobble: Math.random() * 10,
    velocity: opts.startVelocity * 0.5 + Math.random() * opts.startVelocity,
    angle2D: -radAngle + (0.5 * radSpread - Math.random() * radSpread),
    tiltAngle: Math.random() * Math.PI,
    color: opts.color,
    tick: 0,
    totalTicks: opts.ticks,
    decay: opts.decay,
    random: Math.random() + 5,
    tiltSin: 0,
    tiltCos: 0,
    wobbleX: 0,
    wobbleY: 0,
  };
}

function updateFetti(context, fetti) {
  fetti.x += Math.cos(fetti.angle2D) * fetti.velocity;
  fetti.y += Math.sin(fetti.angle2D) * fetti.velocity + 7; // + gravity
  fetti.wobble += 0.1;
  fetti.velocity *= fetti.decay;
  fetti.tiltAngle += 0.1;
  fetti.tiltSin = Math.sin(fetti.tiltAngle);
  fetti.tiltCos = Math.cos(fetti.tiltAngle);
  fetti.random = Math.random() + 5;
  fetti.wobbleX = fetti.x + 15 * Math.cos(fetti.wobble);
  fetti.wobbleY = fetti.y + 15 * Math.sin(fetti.wobble);

  let x1 = fetti.x + fetti.random * fetti.tiltCos;
  let y1 = fetti.y + fetti.random * fetti.tiltSin;
  let x2 = fetti.wobbleX + fetti.random * fetti.tiltCos;
  let y2 = fetti.wobbleY + fetti.random * fetti.tiltSin;

  context.fillStyle = fetti.color;
  context.beginPath();

  context.moveTo(Math.floor(fetti.x), Math.floor(fetti.y));
  context.lineTo(Math.floor(fetti.wobbleX), Math.floor(y1));
  context.lineTo(Math.floor(x2), Math.floor(y2));
  context.lineTo(Math.floor(x1), Math.floor(fetti.wobbleY));

  context.closePath();
  context.fill();

  return fetti.tick < fetti.totalTicks;
}

function animate(canvas, fettis, done) {
  let animatingFettis = fettis.slice();
  let context = canvas.getContext('2d');
  let width = canvas.width;
  let height = canvas.height;

  function onResize() {
    // don't actually query the size here, since this
    // can execute frequently and rapidly
    width = height = null;
  }

  let prom = new Promise(function(resolve) {
    function update() {
      if (!width && !height) {
        // setCanvasSize(canvas);
        width = canvas.width;
        height = canvas.height;
      }

      context.clearRect(0, 0, width, height);

      animatingFettis = animatingFettis.filter(function(fetti) {
        return updateFetti(context, fetti);
      });

      if (animatingFettis.length) {
        frame(update);
      } else {
        window.removeEventListener('resize', onResize);

        done();
        resolve();
      }
    }

    frame(update);
  });

  window.addEventListener('resize', onResize, false);

  return {
    addFettis(fettis) {
      animatingFettis = animatingFettis.concat(fettis);
      return prom;
    },
    canvas,
    promise: prom,
  };
}

function confetti(options) {
  let particleCount = prop(options, 'particleCount', Math.floor);
  let angle = prop(options, 'angle', Number);
  let spread = prop(options, 'spread', Number);
  let startVelocity = prop(options, 'startVelocity', Number);
  let decay = prop(options, 'decay', Number);
  let colors = prop(options, 'colors');
  let ticks = prop(options, 'ticks', Number);
  let origin = getOrigin(options);
  let temp = particleCount;
  let fettis = [];
  let canvas = options.canvas;

  canvas.width = canvas.clientWidth;
  canvas.height = canvas.clientHeight;

  let startX = canvas.width * origin.x;
  let startY = canvas.height * origin.y;

  while (temp--) {
    fettis.push(
      randomPhysics({
        x: startX,
        y: startY,
        angle,
        spread,
        startVelocity,
        color: colors[temp % colors.length],
        ticks,
        decay,
      }),
    );
  }

  animationObj = animate(canvas, fettis, function() {
    animationObj = null;
  });

  return animationObj.promise;
}

module.exports = confetti;
