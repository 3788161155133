var flatten = function(array) {
  return array.reduce(function(previous, current) {
    if (Array.isArray(current)) {
      return previous.concat(flatten(current));
    } else {
      return previous.concat(current);
    }
  }, []);
};

module.exports = flatten;
