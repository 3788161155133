export default {
  admins: {
    64: 'https://static.intercomassets.com/assets/default-avatars/admins/64-24ac7c32f5e329b757d42753f9fc06f46fb8d6f0144660b7827b4a78852965b5.png',
    73: 'https://static.intercomassets.com/assets/default-avatars/admins/73-f018236000e51ae69c92c7e6b48bfb78414886d691f42a45cecb86ab001158fb.png',
    128: 'https://static.intercomassets.com/assets/default-avatars/admins/128-da5b0f4e5162b0f45cba5a162df89b82eaddb92c5f5ae86e18629c8656aa3365.png',
    140: 'https://static.intercomassets.com/assets/default-avatars/admins/140-9a00decfd3c4058c229822a01b95b784f514959a127280597dcfed3df7368bd6.png',
    200: 'https://static.intercomassets.com/assets/default-avatars/admins/200-4dab7e2646ea3c6b2c0f26e297f75f91b64f9adce89b83f21bfaa001c499aab4.png',
  },
  users: {
    64: 'https://static.intercomassets.com/assets/default-avatars/users/64-24ac7c32f5e329b757d42753f9fc06f46fb8d6f0144660b7827b4a78852965b5.png',
    73: 'https://static.intercomassets.com/assets/default-avatars/users/73-f018236000e51ae69c92c7e6b48bfb78414886d691f42a45cecb86ab001158fb.png',
    128: 'https://static.intercomassets.com/assets/default-avatars/users/128-da5b0f4e5162b0f45cba5a162df89b82eaddb92c5f5ae86e18629c8656aa3365.png',
    140: 'https://static.intercomassets.com/assets/default-avatars/users/140-9a00decfd3c4058c229822a01b95b784f514959a127280597dcfed3df7368bd6.png',
    200: 'https://static.intercomassets.com/assets/default-avatars/users/200-4dab7e2646ea3c6b2c0f26e297f75f91b64f9adce89b83f21bfaa001c499aab4.png',
  },
  companies: {
    64: 'https://static.intercomassets.com/ember/assets/svgs/default-company-avatar-47da0586a49f237092d7eff6c4d01a08.svg',
    73: 'https://static.intercomassets.com/ember/assets/svgs/default-company-avatar-47da0586a49f237092d7eff6c4d01a08.svg',
    128: 'https://static.intercomassets.com/ember/assets/svgs/default-company-avatar-47da0586a49f237092d7eff6c4d01a08.svg',
    140: 'https://static.intercomassets.com/ember/assets/svgs/default-company-avatar-47da0586a49f237092d7eff6c4d01a08.svg',
    200: 'https://static.intercomassets.com/ember/assets/svgs/default-company-avatar-47da0586a49f237092d7eff6c4d01a08.svg',
  },
};
