"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanitizeUrl = sanitizeUrl;
var _sanitizeUrl = require("@braintree/sanitize-url");
function sanitizeUrl(url) {
  if (!url) {
    return url;
  }
  return (0, _sanitizeUrl.sanitizeUrl)(url);
}