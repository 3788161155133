"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanitizeHtml = sanitizeHtml;
var _isomorphicDompurify = _interopRequireDefault(require("isomorphic-dompurify"));
var _customSanitizers = _interopRequireDefault(require("./custom-sanitizers.js"));
var _index = _interopRequireDefault(require("./config/index.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// Add the hook only once outside of the function
_isomorphicDompurify["default"].addHook("uponSanitizeElement", function (node, data) {
  if (_customSanitizers["default"][node.tagName]) {
    _customSanitizers["default"][node.tagName](node, data);
  }
});
function sanitizeHtml(html) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _isomorphicDompurify["default"].sanitize(html, (0, _index["default"])(options.config));
}