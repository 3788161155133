"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_CONFIG = void 0;
var COMPOSER_ALLOWED_CUSTOM_ELEMENTS = ["ic-attribute", "ic-block", "ic-marker", "ic-mention"];
var DEFAULT_CONFIG = {
  ADD_TAGS: ["iframe"],
  ADD_ATTR: ["allowfullscreen", "frameborder", "target"],
  CUSTOM_ELEMENT_HANDLING: {
    tagNameCheck: function tagNameCheck(tagName) {
      return COMPOSER_ALLOWED_CUSTOM_ELEMENTS.includes(tagName);
    },
    attributeNameCheck: /^(data-|contenteditable)/,
    allowCustomizedBuiltInElements: false
  }
};
exports.DEFAULT_CONFIG = DEFAULT_CONFIG;