"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getHtmlConfig;
var _emailTemplate = require("./email-template.js");
var _default = require("./default.js");
var _inboxComposer = require("./inbox-composer.js");
var _htmlBlock = require("./html-block.js");
var CONFIGS = {
  "default": _default.DEFAULT_CONFIG,
  email: _emailTemplate.EMAIL_TEMPLATE_CONFIG,
  inboxComposer: _inboxComposer.INBOX_COMPOSER_CONFIG,
  htmlBlock: _htmlBlock.HTML_BLOCK_CONFIG
};
function getHtmlConfig() {
  var configName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "default";
  if (!CONFIGS[configName]) {
    throw new Error("@intercom/intercom-sanitizer: You passed an invalid configuration, `".concat(configName, "`,  to `sanitizeHtml`. The only valid configurations are: `").concat(Object.keys(CONFIGS).join(", "), "`"));
  }
  return CONFIGS[configName];
}