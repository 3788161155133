"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "sanitizeHtml", {
  enumerable: true,
  get: function get() {
    return _sanitizer.sanitizeHtml;
  }
});
Object.defineProperty(exports, "sanitizeUrl", {
  enumerable: true,
  get: function get() {
    return _sanitizer2.sanitizeUrl;
  }
});
var _sanitizer = require("./html/sanitizer.js");
var _sanitizer2 = require("./url/sanitizer.js");