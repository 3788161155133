export function lte(a, b) {
  return a <= b;
}

export function lt(a, b) {
  return a < b;
}

export function gte(a, b) {
  return a >= b;
}

export function gt(a, b) {
  return a > b;
}
