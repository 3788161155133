"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INBOX_COMPOSER_CONFIG = void 0;
var _default = require("./default.js");
var INBOX_COMPOSER_CONFIG = Object.assign({}, _default.DEFAULT_CONFIG, {
  ALLOW_UNKNOWN_PROTOCOLS: true
});
exports.INBOX_COMPOSER_CONFIG = INBOX_COMPOSER_CONFIG;